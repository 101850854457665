const navOptions = [
	{
		title: 'home',
		section: 'Home'
	},	
	{
		title: 'our story',
		section: 'OurStory'
	},	
	{
		title: 'gallery',
		section: 'Gallery'
	},	
	{
		title: 'contact us',
		section: 'Contact'
	},	
	{
		title: 'updates',
		section: 'Updates'
	},
]
export default navOptions;