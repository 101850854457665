import { all, call } from 'redux-saga/effects';

import { userSagas } from './user/user.sagas';
import { updatesSagas } from './updates/updates.sagas';

export default function* rootSaga() {
	yield all([
		call(userSagas),
		call(updatesSagas)
	])
}